import React from "react"
import { Script } from "gatsby"
import Helmet from 'react-helmet';

import { withUser } from './src/legacy/components/Session/index';

const meta = {
  es: {
    title: "Cruza la Frontera rápidamente | Cross Border Xpress",
    meta: [
      { name: 'description', content: "Cross Border Xpress | CBX | El puente peatonal que conecta el Aeropuerto de Tijuana con la ciudad de San Diego, California. Ahorra en tarifas aéreas volando desde Tijuana a 40 destinos en México." },
      {
        name: 'keywords',
        content: "cbx, cross border xpress, cbx tijuana, cbx cruce, cbx puente, cbx san diego, puente aeropuerto, aeropuerto de tijuana, cross border express, vuela a tijuana, cruza a san diego, vuela a los angeles"
      },
      { name: 'viewport', content: 'width=device-width' }
    ]
  },
  en: {
    title: "Border crossing made easy | Cross Border Xpress",
    meta: [
      { name: 'description', content: "Cross Border Xpress | CBX |The pedestrian bridge that connects San Diego with The Tijuana International Airport. Save on airfare flying from Tijuana to 40 destinations in Mexico." },
      {
        name: 'keywords',
        content: "cbx, cross border xpress, cbx tijuana, cbx crossing, cbx border crossing, cbx san diego, cbx bridge, aeropuerto de tijuana, tijuana airport bridge, cross border express, fly to tijuana, fly to Mexico, parking near cbx"
      },
      { name: 'viewport', content: 'width=device-width' }
    ]
  }
}

export const wrapPageElement = ({ element, props: { pageContext: { language } } }) => {

  console.log(language);
  const SessionWrapper = ({ firebase, user, uid }) => {
    if (!element.props.children) {
      return <div id={uid}>{React.cloneElement(element, {
        user,
        firebase,
        uid
      })}</div>
    }
    const childrenWithProps = React.Children.map(element.props.children, (child, index) => {
      if (!child.props.children) {
        return React.cloneElement(child, {
          user,
          firebase,
          uid
        });
      }
      const otherChildrenWithProps = React.Children.map(child.props.children, (subchild, index) => {
        return React.cloneElement(subchild, {
          user,
          firebase,
          uid
        });
      });
      return React.cloneElement(child, {
        user,
        firebase,
        uid
      }, ...otherChildrenWithProps);
    });

    return <div id={uid}>{React.cloneElement(element, {
      user,
      firebase,
      uid
    }, ...childrenWithProps)}</div>
  }

  const SessionComponent = withUser(SessionWrapper);

  let helmetData = meta[language];
  if (!helmetData) helmetData = meta['en'];

  return (
    <>
      <Script>
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TN6F6PM');
            `
        }
      </Script>
      {false && <Script async src="https://www.googletagmanager.com/gtag/js?id=AW-626695262"></Script>}
      <Script>
        {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'AW-626695262');
        `}
      </Script>
      {false && <Script async src="https://www.googletagmanager.com/gtag/js?id=G-D50811N9W5"></Script >}
      {false && <Script>
        {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-D50811N9W5');
        `}
      </Script>}
      {false && <Script async src="https://www.googletagmanager.com/gtag/js?id=G-L4THJE5F62"></Script >}
      {false && <Script>
        {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-L4THJE5F62');
        `}
      </Script>}
      <Script>
        {`(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2611834,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
      </Script>

      <Helmet
        title={helmetData.title}
        meta={helmetData.meta}
      >
      </Helmet>
      <SessionComponent>
        {element}
      </SessionComponent>
    </>
  )
}

