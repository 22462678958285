import React from 'react';

import { Checkbox, Grid, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import TypographyLink from '../../../components/TypographyLink';
import { TravelClub, travelClubMemberSchema } from '../../../domain/travel-club';
import { FormTextFieldController } from '../../Checkout/components/CheckoutContactInformation/components/text-field-controller';

// ********************************************************************************
type Props = {
  travelClub: TravelClub;
  handleCartChange: (key: string, val: any) => void;
};
export const TravelClubContactForm: React.FC<Props> = ({ travelClub, handleCartChange }) => {
  const { t } = useTranslation();

  // == State ===============================================================
  const form = useForm({
    mode: 'all',
    defaultValues: {
      isSamePerson: travelClub.member.isSamePerson || false,
      acceptRegion: travelClub.member.acceptRegion || false,
      firstName: travelClub.member.firstName,
      lastName: travelClub.member.lastName,
      email: travelClub.member.email,
    },
    resolver: yupResolver(travelClubMemberSchema),
  });

  const onItemUpdate = (key: string, val: any) => {
    handleCartChange(key, val);
  };

  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={1}>
        <Typography variant="body1" color="secondary" fontWeight={700}>
          {t('travel_club_service.contact_information.title')}
        </Typography>
        <Typography variant="body2" color="secondary" fontWeight={700} mb={2}>
          {t(`travel_club_service.plans.${travelClub.plan.key}.title`)}
        </Typography>
        <Stack direction="column" gap={3}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Controller
              control={form.control}
              name="isSamePerson"
              render={({ field }) => (
                <Checkbox
                  sx={{ color: '#6C6D70', padding: 0 }}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    onItemUpdate('isSamePerson', e.target.checked);
                  }}
                />
              )}
            />
            <Typography variant="body2" color="secondary.light">
              {t('travel_club_service.contact_information.same_as_main_traveler')}
            </Typography>
          </Stack>

          {!form.watch('isSamePerson') && (
            <Grid container spacing={2.5}>
              <Grid item xs={12} md={4}>
                <FormTextFieldController
                  name="firstName"
                  label={t('checkout_contact_information.main_traveler.inputs.name')}
                  placeholder={t('checkout_contact_information.main_traveler.inputs.name_placeholder')}
                  inputProps={{ minLength: 2, maxLength: 50 }}
                  onChange={(val) => {
                    onItemUpdate('firstName', val);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextFieldController
                  name="lastName"
                  label={t('checkout_contact_information.main_traveler.inputs.last_name')}
                  placeholder={t('checkout_contact_information.main_traveler.inputs.last_name_placeholder')}
                  inputProps={{ minLength: 2, maxLength: 100 }}
                  onChange={(val) => {
                    onItemUpdate('lastName', val);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormTextFieldController
                  name="email"
                  label={t('checkout_contact_information.main_traveler.inputs.email')}
                  placeholder={t('checkout_contact_information.main_traveler.inputs.email_placeholder')}
                  type="email"
                  onChange={(val) => {
                    onItemUpdate('email', val);
                  }}
                />
              </Grid>
            </Grid>
          )}

          <Stack direction="row" alignItems="center" gap={0.5}>
            <Controller
              control={form.control}
              name="acceptRegion"
              render={({ field }) => (
                <Checkbox
                  sx={{ color: '#6C6D70', padding: 0 }}
                  checked={field.value}
                  required={true}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                    onItemUpdate('acceptRegion', e.target.checked);
                  }}
                />
              )}
            />
            <Typography variant="body2" color="secondary.light">
              <Trans
                i18nKey="travel_club_service.contact_information.accept_region"
                components={[
                <TypographyLink href="/travel-club/terms" color="primary.light" underline />,
                <TypographyLink href="/privacy" color="primary.light" underline />,
                <Typography component="span" variant="body2" fontFamily="Gotham-Bold" />,
              ]}
              />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
