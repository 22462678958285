export enum FamilyPackTicket {
  Family4 = 4,
  Family5 = 5,
  Family6 = 6,
  Family7 = 7,
  Family8 = 8,
  Family9 = 9,
  Family10 = 10,
  Family11 = 11,
  Family12 = 12,
  Family13 = 13,
  Family14 = 14,
  Family15 = 15,
}

export const DEFAULT_ES_FAMILY_PACKAGES: { label: String; passengers: FamilyPackTicket }[] = [
  {
    label: 'Familia 4 Individual',
    passengers: FamilyPackTicket.Family4,
  },
  {
    label: 'Familia 5 Individual',
    passengers: FamilyPackTicket.Family5,
  },
  {
    label: 'Familia 6 Individual',
    passengers: FamilyPackTicket.Family6,
  },
  {
    label: 'Familia 7 Individual',
    passengers: FamilyPackTicket.Family7,
  },
  {
    label: 'Familia 8 Individual',
    passengers: FamilyPackTicket.Family8,
  },
  {
    label: 'Familia 9 Individual',
    passengers: FamilyPackTicket.Family9,
  },
  {
    label: 'Familia 10 Individual',
    passengers: FamilyPackTicket.Family10,
  },
  {
    label: 'Familia 11 Individual',
    passengers: FamilyPackTicket.Family11,
  },
  {
    label: 'Familia 12 Individual',
    passengers: FamilyPackTicket.Family12,
  },
  {
    label: 'Familia 13 Individual',
    passengers: FamilyPackTicket.Family13,
  },
  {
    label: 'Familia 14 Individual',
    passengers: FamilyPackTicket.Family14,
  },
  {
    label: 'Familia 15 Individual',
    passengers: FamilyPackTicket.Family15,
  },
];

export const DEFAULT_EN_FAMILY_PACKAGES = [
  {
    label: 'Family 4 Individual',
    passengers: FamilyPackTicket.Family4,
  },
  {
    label: 'Family 5 Individual',
    passengers: FamilyPackTicket.Family5,
  },
  {
    label: 'Family 6 Individual',
    passengers: FamilyPackTicket.Family6,
  },
  {
    label: 'Family 7 Individual',
    passengers: FamilyPackTicket.Family7,
  },
  {
    label: 'Family 8 Individual',
    passengers: FamilyPackTicket.Family8,
  },
  {
    label: 'Family 9 Individual',
    passengers: FamilyPackTicket.Family9,
  },
  {
    label: 'Family 10 Individual',
    passengers: FamilyPackTicket.Family10,
  },
  {
    label: 'Family 11 Individual',
    passengers: FamilyPackTicket.Family11,
  },
  {
    label: 'Family 12 Individual',
    passengers: FamilyPackTicket.Family12,
  },
  {
    label: 'Family 13 Individual',
    passengers: FamilyPackTicket.Family13,
  },
  {
    label: 'Family 14 Individual',
    passengers: FamilyPackTicket.Family14,
  },
  {
    label: 'Family 15 Individual',
    passengers: FamilyPackTicket.Family15,
  },
];
