import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material/styles';

import ShoppingCartProvider from './src/context/ShoppingCart/shopping-cart-provider';
import { ServiceProvider } from './src/context/ServiceContext';
import theme from './src/theme';

import Firebase, { FirebaseContext } from './src/legacy/components/Firebase';

import './src/reset.css';
import './src/fonts/fonts.css';
import './src/global.css';
import './src/reset.css';
import './src/utils/yup-locale';

export { wrapPageElement } from "./gatsby-shared"

export const wrapRootElement = ({ element }) => {
  return (
    <ServiceProvider>
      <ShoppingCartProvider>
        {false && <Helmet>
          <meta charSet="utf-8" />
          <title>CBX</title>
          <link rel="icon" href="/favicon.png" />
        </Helmet>}
        <ThemeProvider theme={theme}>
          <FirebaseContext.Provider value={new Firebase()}>
            {element}
          </FirebaseContext.Provider>
        </ThemeProvider>
      </ShoppingCartProvider>
    </ServiceProvider>
  );
};

/*
export const wrapPageElement = ({ element, props }) => {

  const SessionWrapper = ({ firebase, user, uid }) => {
    if (!element.props.children) {
      return <div id={uid}>{React.cloneElement(element, {
        user,
        firebase,
        uid
      })}</div>
    }
    const childrenWithProps = React.Children.map(element.props.children, (child, index) => {
      console.log(child);
      if (!child.props.children) {
        return React.cloneElement(child, {
          user,
          firebase,
          uid
        });
      }
      const otherChildrenWithProps = React.Children.map(child.props.children, (subchild, index) => {
        return React.cloneElement(subchild, {
          user,
          firebase,
          uid
        });
      });
      return React.cloneElement(child, {
        user,
        firebase,
        uid
      }, ...otherChildrenWithProps);
    });

    return <div id={uid}>{React.cloneElement(element, {
      user,
      firebase,
      uid
    }, ...childrenWithProps)}</div>
  }

  const SessionComponent = withUser(SessionWrapper);

  return <SessionComponent />

}
*/
const getLanguage = () => {
  if (typeof window === 'undefined') throw Error('UndefinedWindowError');
  const pathParts = window.location.pathname.split('/');
  if (pathParts.length < 2) throw Error('NoLocaleInPathPartsError')
  const languageCandidate = pathParts[1];
  if (!supportedLanguages.includes(languageCandidate)) throw Error('UnsupportedLocaleError');
  return languageCandidate;
}

export const onClientEntry = () => {
  const chatSettings = {
    useBrowserLanguage: false
  };
  try {
    const language = getLanguage();
    chatSettings.locale = language;
  } catch (error) {
    chatSettings.useBrowserLanguage = true;
  }
  window.chatwootSettings = { "position": "right", "type": "expanded_bubble", "useBrowserLanguage": "true", ...chatSettings };
  (function(d, t) {
    var BASE_URL = "https://cbx-chat.com";
    var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
    g.src = BASE_URL + "/packs/js/sdk.js";
    g.defer = true;
    g.async = false;
    s.parentNode.insertBefore(g, s);
    g.onload = function() {
      window.chatwootSDK.run({
        websiteToken: 'jaRsrVDxdfEPcMDnfssw4AmY',
        baseUrl: BASE_URL
      })
    }
  })(document, "script");
}
