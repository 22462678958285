import React, { createContext, useContext, useState } from "react";

const defaultState = {
  selectedServices: [],
  addService: () => { },
  removeService: () => { },
  clearServices: () => { }
}

export const ServiceContext = createContext(defaultState);

export const useServiceContext = () => {
  return useContext(ServiceContext);
};

export const ServiceProvider = ({ children }) => {
  const [selectedServices, setSelectedServices] = useState([]);

  const addService = (service) => {
    setSelectedServices([...selectedServices, service]);
  };

  const removeService = (service) => {
    const updatedServices = selectedServices.filter(
      (selectedService) => selectedService !== service
    );
    setSelectedServices(updatedServices);
  };

  const clearServices = () => {
    setSelectedServices([]);
  };

  return (
    <ServiceContext.Provider
      value={{
        selectedServices,
        addService,
        removeService,
        clearServices,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
};
