// ********************************************************************************
// NOTE: This plans are in sync with the i18n keys in the translations
//       file. If the keys are changed, on either side, the other side
//       should be updated as well.
export enum TravelClubPlans {
  '30_DAYS' = '30_days',
  'MONTHLY' = 'monthly',
  'ANNUAL' = 'annual',
}

// ................................................................................
type BaseTravelClubPlan = {
  key: TravelClubPlans;
  price: number;
  recurring: boolean;
  hasDiscount: boolean;
};

type TravelClubPlanWithoutDiscount = BaseTravelClubPlan & { hasDiscount: false };
type TravelClubPlanWithDiscount = BaseTravelClubPlan & {
  hasDiscount: true;
  discountPrice: number;
};
export type TravelClubPlan = TravelClubPlanWithoutDiscount | TravelClubPlanWithDiscount;

// == Plan Prices =================================================================
export const travelClub30DayPlan: TravelClubPlan = {
  key: TravelClubPlans['30_DAYS'],
  price: 10,
  recurring: false,
  hasDiscount: true,
  discountPrice: 4.95,
};

export const travelClubMonthlyPlan: TravelClubPlan = {
  key: TravelClubPlans['MONTHLY'],
  price: 0 /*TBD*/,
  recurring: true,
  hasDiscount: false,
};

export const travelClubAnnualPlan: TravelClubPlan = {
  key: TravelClubPlans['ANNUAL'],
  price: 0 /*TBD*/,
  recurring: false,
  hasDiscount: false,
};
