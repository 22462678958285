// @ts-check
import { createContext } from "react";
import { TripType } from "../../types/tripType";

/**
 * @typedef {import('../../types/Ticket').SingleTicket} SingleTicket
 * @typedef {import('../../types/Ticket').GroupRate} GroupRate
 * @typedef {import('../../types/Ticket').PackageDeal} PackageDeal
 * @typedef {import('../../types/Parking').Parking} Parking
 * @typedef {import('../../types/Shuttle').Shuttle} Shuttle
 * @typedef {import('../../types/serviceType').ServiceType} ServiceType
 */

/**
 * @typedef {Object} ShoppingCartItem
 * @property {ServiceType} type
 * @property {SingleTicket | GroupRate | PackageDeal | Parking | Shuttle} item
 * @property {number} quantity
 */

/**
 * @typedef {Object} ShoppingCart
 * @property {ShoppingCartItem[]} items
 * @property {number} total
 */

/**
 * @typedef {Object} ShoppingCartContextType
 * @property {ShoppingCart} cart
 * @property {boolean} isOpen
 * @property {boolean} openShoppingCartAddServices
 * @proerty  {function} setOpenShoppingCartAddServices
 * @property {function} addServiceToCart
 * @property {function} checkCheckoutCart
 * @property {function} buildCheckoutCart
 * @property {function} buildContactInfo
 * @property {function} removeServiceFromCart
 * @property {function} openShoppingCart
 * @property {function} closeShoppingCart
 * @property {function} notifySuccessPurchase

 */

/**
 * @type {ShoppingCartContextType}
 */

export const initialShoppingCartContext = {
  cart: {
    items: [],
    total: 0,
  },
  isOpen: false,
  openShoppingCartAddServices: false,
  addServiceToCart: (service, type) => { },
  buildCheckoutCart: () => { },
  removeServiceFromCart: (service) => { },
  openShoppingCart: () => { },
  setOpenShoppingCartAddServices: () => { },
  closeShoppingCart: () => { },
  notifySuccessPurchase: (transactionId) => { },
  calculateTotal: () => 0,
  promoCode: "",
  applyPromoCode: (promoCode: any) => { },
  removePromoCode: () => { },
};

export const ShoppingCartContext = createContext(initialShoppingCartContext);
