import {
  AssistedTicketFlight,
  AssistedTicketPassenger,
  DEFAULT_ASSISTED_TICKET_FLIGHT,
  DEFAULT_ASSISTED_TICKET_PASSENGER,
} from './assisted-ticket';
import { TicketAirline } from './ticket-airline';
import { TicketCompany } from './ticket-company';

export interface TicketAssistedPass {
  flightInformation: AssistedTicketFlight;
  passengerInformation: AssistedTicketPassenger;
}

export const buildDefaultTicketAssistedPass = (
  ticketCompany: TicketCompany,
  departureDate: string,
): TicketAssistedPass => {
  const points = ticketCompany === TicketCompany.SanDiego ? { origin: 'TIJ-TJX' } : { destination: 'TIJ-TJX' };

  return {
    flightInformation: { ...DEFAULT_ASSISTED_TICKET_FLIGHT, date: departureDate, ...points },
    passengerInformation: { ...DEFAULT_ASSISTED_TICKET_PASSENGER },
  };
};

const ASSISTED_PASS_AIRLINES = [
  TicketAirline.Volaris,
  TicketAirline.Aeromexico,
  TicketAirline.VivaAerobus,
  TicketAirline.Calafia,
] as const;

export const getAssistedPassAirlines = () => ASSISTED_PASS_AIRLINES;
