import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { AssistedTicketFlight, AssistedTicketPassenger, getAssistedPassAirlines } from '../../../../domain/tickets';
import { isOneWayTrip } from '../../../../domain/travels';
import { Yup } from '../../../../utils/yup-locale';
import { AssistedTicketFlightForm } from './assisted-ticket-flight-form';
import { AssistedTicketPassengerForm } from './assisted-ticket-passenger-form';

const assistedTicketFlightSchema = Yup.object().shape({
  airline: Yup.string(),
  date: Yup.string(),
  destination: Yup.string(),
  flightNumber: Yup.string(),
  origin: Yup.string(),
});

const assistedTicketPassengerSchema = Yup.object().shape({
  age: Yup.number(),
  firstName: Yup.string(),
  gender: Yup.string(),
  lastName: Yup.string(),
  nationality: Yup.string(),
  telephone: Yup.string(),
});

interface Props {
  ticket: any;
  onFlightChange: (field: keyof AssistedTicketFlight, value: string) => void;
  onPassengerChange: (field: keyof AssistedTicketPassenger, value: number | string) => void;
}

export const AssistedPassContactInfoForm = ({ ticket, onFlightChange, onPassengerChange }: Props) => {
  const { t } = useTranslation();
  const travelTypei18n = isOneWayTrip(ticket.tripType) ? 'trip_types.one_way' : 'trip_types.round_trip';

  return (
    <>
      <Stack gap={1}>
        <Typography fontWeight={700}>
          {t('ticket')} {t(travelTypei18n)}
        </Typography>
        <Stack flexDirection="row" gap={1}>
          <Typography fontWeight={700}>{t('tickets_service.passenger_types.assisted_traveler')}:</Typography>
          <Stack flexDirection="row" gap={2}>
            <Typography>{ticket.origin}</Typography>
            <Typography>-</Typography>
            <Typography>{ticket.destination}</Typography>
          </Stack>
        </Stack>
      </Stack>

      <AssistedTicketPassengerForm
        passenger={ticket.assisted_data.passengerInformation}
        validationSchema={assistedTicketPassengerSchema}
        onChange={onPassengerChange}
      />
      <AssistedTicketFlightForm
        airlines={getAssistedPassAirlines()}
        flight={ticket.assisted_data.flightInformation}
        validationSchema={assistedTicketFlightSchema}
        onChange={onFlightChange}
      />
    </>
  );
};
