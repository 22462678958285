import axios from 'axios';
import moment from 'moment-timezone';
import { TicketType, TicketUnaccompaniedMinorTempFileId } from '../../domain/tickets';
import { TICKETS_SERVICE_ENDPOINTS } from './tickets.service.config';
import {
  GetTicketsConfigResponse,
  UpdateAssistedPassReqParams,
  UploadUnaccompaniedMinorTempFileReqParams,
} from './tickets.service.type';

const axiosInstance = axios.create();

export class TicketsService {
  private readonly _endpoints: typeof TICKETS_SERVICE_ENDPOINTS;

  constructor() {
    this._endpoints = { ...TICKETS_SERVICE_ENDPOINTS };
  }

  public static calculateTicketPrice({
    company,
    departureDate,
    returnDate,
    isFamily,
    isRoundTrip,
    packages,
    passengers,
    ticketType,
  }: {
    company: number;
    departureDate: string;
    returnDate?: string;
    isFamily: boolean;
    isRoundTrip: boolean;
    packages: any[];
    passengers: number;
    ticketType: TicketType;
  }) {
    /**
     * Calculates the current amount
     * @param {Object} datumDate - Date in Datum format
     * @returns {string}
     */
    const momentDate = (datumDate) => {
      const dateTz = parseInt(datumDate.split('(')[1].split('-')[0], 10);
      return new Date(
        moment(dateTz).tz('America/Tijuana').valueOf() + moment(dateTz).tz('America/Tijuana').utcOffset() * 60 * 1000,
      )
        .toISOString()
        .substring(0, 10);
    };
    const calculateValueToDate = (filterDate) => {
      const values = packages
        .filter((datum = {}) => datum.IdCompany === company)
        .filter((datum = {}) => (isRoundTrip ? datum.IdCrossingType === 3 : datum.IdCrossingType !== 3))
        .filter((datum = {}) => datum.IdProdClassification === ticketType);

      if (values.length) {
        const productPacks = values[0].ProductPacks;
        if (!isFamily) {
          const [ticketProduct] = productPacks.filter((datum) => {
            const dtStart = momentDate(datum.DtStart);
            const dtEnd = momentDate(datum.DtEnd);
            return dtStart <= filterDate && filterDate <= dtEnd;
          });
          if (!!ticketProduct) {
            const ticketPrice = ticketProduct.Amount * passengers;
            const ticketDisplayPrice = ticketProduct.DisplayAmount * passengers;
            const ticketProducts = Object.assign({}, ticketProduct, {
              NuProdQuant: passengers,
            });
            if (ticketPrice > 0) {
              const retProducts = [ticketProducts];
              const displayCurrency = ticketProduct.DisplayCurrency;
              return {
                products: retProducts,
                total: `$${ticketDisplayPrice.toFixed(2)} ${displayCurrency}`,
                amount: ticketPrice,
                displayPrice: ticketDisplayPrice,
                displayCurrency,
              };
            }
          }
        } else {
          const ticketProducts = productPacks.filter((datum) => {
            const dtStart = momentDate(datum.DtStart);
            const dtEnd = momentDate(datum.DtEnd);
            return datum.NuPersonQuant === passengers && dtStart <= filterDate && filterDate <= dtEnd;
          });
          const ticketPrice = ticketProducts.reduce((acc, datum) => {
            const numberOfPackages = datum.NuProdQuant;
            const amount = datum.Amount;
            return acc + numberOfPackages * amount;
          }, 0);
          const ticketDisplayPrice = ticketProducts.reduce((acc, datum) => {
            const numberOfPackages = datum.NuProdQuant;
            const amount = datum.DisplayAmount;
            return acc + numberOfPackages * amount;
          }, 0);
          const response = [...ticketProducts];
          ticketProducts.forEach((prod, index) => {
            const localProd = { ...prod };
            if (prod.NuPassenger === 1) {
              localProd.IdProdClassification = 2;
            } else {
              localProd.IdProdClassification = 3;
            }
            response[index] = localProd;
          });
          if (ticketPrice > 0) {
            const displayCurrency = ticketProducts[0].DisplayCurrency;
            return {
              products: response,
              total: `$${ticketDisplayPrice.toFixed(2)} ${displayCurrency}`,
              amount: ticketPrice,
              displayPrice: ticketDisplayPrice,
              displayCurrency,
            };
          }
        }
      }

      return { products: [], total: ' ', amount: 0, displayPrice: 0, displayCurrency: '' };
    }

    const ticketDateValues = calculateValueToDate(departureDate);

    const returnDateValues = isRoundTrip
      ? calculateValueToDate(returnDate)
      : ticketDateValues;
    const valuesToReturn = ticketDateValues.amount > returnDateValues.amount ? ticketDateValues : returnDateValues;

    return valuesToReturn;

  }

  public async getAssistedPass(ticketId: string): Promise<{
    assistedData: any /** TODO: Remove any, set the correct type */;
    travelDestination: string;
    prodClassification: any /** TODO: Remove any, set the correct type */;
  }> {
    return (await axiosInstance.get(this._endpoints.getAssistedPass(ticketId))).data;
  }

  public async getTicketsConfig(): Promise<GetTicketsConfigResponse> {
    return (await axiosInstance.get(this._endpoints.getTicketsConfig)).data.data;
  }

  public async updateAssistedPass(params: UpdateAssistedPassReqParams): Promise<void> {
    await axiosInstance.post(this._endpoints.updateAssistedPass, params);
  }

  private async getSignedUrl(
    tempFileId: TicketUnaccompaniedMinorTempFileId,
    fileName: string,
  ): Promise<{ signedUrl: string }> {
    return (
      await axiosInstance.get(this._endpoints.getUnaccompaniedMinorTempFilesUrl, {
        params: { id: tempFileId, name: fileName },
      })
    ).data;
  }

  public async uploadUnaccompaniedMinorTempFile({
    tempFileId,
    fileName,
    fileBuffer,
    fileBufferType,
  }: UploadUnaccompaniedMinorTempFileReqParams) {
    const { signedUrl } = await this.getSignedUrl(tempFileId, fileName);

    await axiosInstance.put(signedUrl, fileBuffer, {
      headers: { 'Content-Type': fileBufferType },
    });
  }
}
