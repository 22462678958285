import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormTextField, FormTextFieldProps } from '../../../../../components/form';

// ********************************************************************************
type Props = {
  name: string;
  // onChange is optional, it is used to update the form state when the value changes
  onChange?: (value: string) => void;
} & Omit<FormTextFieldProps, 'name' | 'value' | 'onChange'>;
export const FormTextFieldController = ({ name, onChange, ...props }: Props) => {
  const { t } = useTranslation();
  const { control, formState } = useFormContext();
  // == State ===============================================================
  const error = formState.touchedFields[name] && formState.errors[name];

  // == Render ==============================================================
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormTextField
          name={name}
          value={field.value}
          onChange={(e) => {
            field.onChange(e);
            if (onChange) onChange(e.target.value);
          }}
          onBlur={field.onBlur}
          error={!!error}
          helperText={error ? t(`${error.message}` /*cast*/) : ''}
          {...props}
        />
      )}
    />
  );
};
