export enum TicketTravelReason {
  Visit = 'visit',
  Leisure = 'leisure',
  Business = 'business',
  Other = 'other',
}

export const DEFAULT_ES_REASONS: { label: string; value: TicketTravelReason }[] = [
  {
    label: 'Visitar amigos y/o familiares',
    value: TicketTravelReason.Visit,
  },
  {
    label: 'Placer',
    value: TicketTravelReason.Leisure,
  },
  {
    label: 'Negocios',
    value: TicketTravelReason.Business,
  },
  {
    label: 'Otro',
    value: TicketTravelReason.Other,
  },
];

export const DEFAULTS_EN_REASONS = [
  {
    label: 'Visit friends or family',
    value: TicketTravelReason.Visit,
  },
  {
    label: 'Leisure',
    value: TicketTravelReason.Leisure,
  },
  {
    label: 'Business',
    value: TicketTravelReason.Business,
  },
  {
    label: 'Other',
    value: TicketTravelReason.Other,
  },
];
