import React from 'react';

import AuthUserContext from '../context';
import { withFirebase } from '../../Firebase';

/**
 * with authentication hoc
 * @param {function} Component - component hoc
 * @returns {function}
 */
const withAuthentication = (Component) => {
  /**
   * With Authentication class
   */
  class WithAuthentication extends React.Component {
    /**
     * constructor class
     * @param {*} props - class props
     */
    constructor(props) {
      super(props);

      this.state = {
        authUser: null
      };
    }

    /**
     * Component did mount logic
     */
    componentDidMount() {
      const { firebase } = this.props;

      this.listener = firebase.auth.onAuthStateChanged((authUser) => {
        const authorizedUser = authUser || null;

        this.setState({
          authUser: authorizedUser
        });
      });
    }

    /**
     * Component unmount logic
     */
    componentWillUnmount() {
      this.listener();
    }

    /**
     * Render method
     * @returns {JSX}
     */
    render() {
      const { authUser } = this.state;
      return (
        <AuthUserContext.Provider value={authUser}>
          <AuthUserContext.Consumer>
            {userInfo => <Component {...this.props} userInfo={userInfo} />}
          </AuthUserContext.Consumer>
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
