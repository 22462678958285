import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3F2A55", // primaryBrand
      light: "#EF60A3", // primaryBrandVariant
    },
    secondary: {
      main: "#282032", // neutroTitles
      light: "#333334", // neutroParagraphs
      dark: "#E8E8E8", // neutroCaptions
      contrastText: "#6C6D70", // neutroCaptionsVariant
    },
    error: {
      main: "#CF1E3C", // stateError
    },
    warning: {
      main: "#FFA726", // stateAttention
    },
    info: {
      main: "#FBFBFB", // neutroBackground
    },
    success: {
      main: "#009C5A", // stateConfirmation
    },
    text: {
      primary: "#333334", // neutroParagraphs
      secondary: "#E8E8E8", // neutroCaptions
      disabled: "#6C6D70", // neutroCaptionsVariant
    },
    background: {
      default: "#FBFBFB", // neutroBackground
      paper: "#FEFEFE", // neutroBlank
    },
    action: {
      active: "#6C6D70", // neutroCaptionsVariant
      hover: "#E8E8E8", // neutroCaptions
      selected: "#282032", // neutroTitles
      disabled: "#6C6D70", // neutroCaptionsVariant
      disabledBackground: "#FBFBFB", // neutroBackground
    },
  },
  typography: {
    fontFamily: "Gotham-Book",
  },
  components: {
    MuiClock: { 
      styleOverrides: {
        root: {
          "& .MuiClockNumber-root": {
            color: "#333334",
          }
        }
      }
    },
    MuiChip: {
      variants: [
        {
          props: { variant: "filled", color: "primary" },
          style: {
            backgroundColor: "#EF60A3",
            color: "white",
            "& .MuiChip-icon": {
              color: "white",
            },
            "&:hover": {
              backgroundColor: "#EC3285",
            },
            "&:focus": {
              backgroundColor: "#EC3285",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            border: "1px solid white",
            color: "white",
            "& .MuiChip-icon": {
              color: "white",
            },
          },
        },
      ],
    },

    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#EF60A3",
            fontWeight: 700,
            "&:hover": {
              backgroundColor: "#EC3285",
            },
            "&:focus": {
              backgroundColor: "#EC3285",
            },
          },
        },
      },
    },
    MuiCheckbox: {
      variants: [
        {
          props: { variant: "grey" },
          style: {
            color: "#6C6D70",
            "&.Mui-checked": {
              color: "#6C6D70",
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "grey" },
          style: {
            color: "#6C6D70 !important",
            backgroundColor: "#E6E7E9 !important",

            height: "44px !important",
            padding: "12px 24px",
          },
        },
        {
          props: { variant: "text-grey", size: "large" },
          style: {
            color: "#6C6D70 !important",
            fontWeight: 700,

            border: "1px solid #6C6D70",
            borderRadius: "8px",

            height: "44px !important",
            padding: "12px 24px",
          },
        },
        {
          props: { variant: "outlined-white", size: "large" },
          style: {
            "&&": {
              color: "#333334",
              border: "1px solid #EAEAEB",
              borderRadius: "8px",

              height: "44px",
              padding: "12px 24px",
            },
          },
        },
        {
          props: { variant: "text-white", size: "medium" },
          style: {
            color: "#F2F4F9",
            border: "none",
            borderRadius: "0",

            height: "36px",
            padding: "8px 16px",

            boxShadow: "none",
          },
        },
        {
          props: { variant: "subscribe", size: "large" },
          style: {
            color: "#FFF",
            border: "none",
            borderRadius: "8px",

            cursor: "pointer",

            minWidth: "160px",

            height: "44px",
            padding: "12px 24px",
            position: "relative",
            overflow: "hidden",
            backgroundColor: "#3F2A55",

            transition: "color 300ms, background-color 0.4s",

            "&::before": {
              content: "''",
              background: "#EF60A3",
              clipPath: "polygon(0% 0%, 100% 0, 100% 50%, 100% 100%, 0% 100%)",
              transition: "clip-path 0.4s cubic-bezier(0.2, 1, 0.8, 1)",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            },

            "&:hover::before": {
              clipPath: "polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%)",
            },

            "& span": {
              display: "block",
              transform: "translate3d(0,0,0)",
              transition: "transform 0.4s cubic-bezier(0.2, 1, 0.8, 1)",
              mixBlendMode: "unset",
            },

            "&:hover span": {
              transform: "translate3d(-10px,0,0)",
            },

            "@media (max-width: 420px)": {
              "&::before": {
                clipPath:
                  "polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%) !important",
              },

              "&:hover::before": {
                clipPath:
                  "polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%) !important",
              },
            },

            "@media (max-width: 600px)": {
              "&::before": {
                clipPath:
                  "polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%) !important",
              },

              "&:hover::before": {
                clipPath:
                  "polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%) !important",
              },
            },

            "@media (max-width: 900px)": {
              "&::before": {
                clipPath:
                  "polygon(0% 0%, 100% 0%, 100% 50%, 100% 100%, 0% 100%) !important",
              },

              "&:hover::before": {
                clipPath:
                  "polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%) !important",
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "4px",

          fontWeight: 600,
          textTransform: "none",

          // Estilos para botones primarios (contained)
          "&.MuiButton-contained": {
            backgroundColor: "#EF60A3", // Active
            border: "1.5px solid transparent",
            "&:hover": {
              backgroundColor: "#EC3285", // Hover
              boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.05)",
            },
            "&:focus": {
              border: "1.5px solid #E04B8D",
              backgroundColor: "#EF60A3", // Focus
            },
            "&:active": {
              boxShadow: "0px 0px 2px 3px rgba(224, 75, 141, 0.30)",
              backgroundColor: "#EF60A3", // Pressed
            },
            "&.Mui-disabled": {
              opacity: 0.8,
              color: '#FFF',
            },
            "&.MuiButton-sizeMedium": {
              height: "36px",
              padding: "8px 16px",
            },
            "&.MuiButton-sizeLarge": {
              height: "44px",
              padding: "12px 24px",
            },
            "&.MuiButton-sizeSmall": {
              height: "28px",
              padding: "4px 12px",
            },
          },

          // Estilos para botones secundarios (outlined)
          "&.MuiButton-outlined": {
            border: "1px solid #3F2A55",
            backgroundColor: "#FDFDFD", // Active
            "&:hover": {
              color: "#3F2A55",
              backgroundColor: "rgba(63, 42, 85, 0.20)", // Hover
            },
            "&:focus": {
              color: "#FDFDFD",
              backgroundColor: "rgba(63, 42, 85, 0.60)", // Focus
            },
            "&:active": {
              color: "#FDFDFD",
              boxShadow: "0px 0px 2px 3px rgba(63, 42, 85, 0.30)",
              backgroundColor: "#3F2A55", // Pressed
            },
            "&.Mui-disabled": {
              color: "#8C9196",
              border: "1px solid #D2D5D8",
              backgroundColor: "#F6F7FA", // Disabled
            },
            "&.MuiButton-sizeMedium": {
              height: "36px",
              padding: "8px 16px",
            },
            "&.MuiButton-sizeLarge": {
              height: "44px",
              padding: "12px 24px",
            },
            "&.MuiButton-sizeSmall": {
              height: "28px",
              padding: "4px 12px",
            },

            "&:has(.MuiButton-startIcon), &:has(.MuiButton-endIcon)": {
              color: "#E04B8D",
              border: "1px solid #EF60A3",
              backgroundColor: "#FDFDFD", // Active
              "&:hover": {
                backgroundColor: "rgba(224, 75, 141, 0.20)", // Hover
              },
              "&:focus": {
                color: "#FDFDFD",
                backgroundColor: "#F17DB2", // Focus
              },
              "&:active": {
                color: "#FDFDFD",
                boxShadow: "0px 0px 2px 3px rgba(224, 75, 141, 0.30)",
                backgroundColor: "#E04B8D", // Pressed
              },
              "&.Mui-disabled": {
                color: "#8C9196",
                border: "1px solid #D2D5D8",
                backgroundColor: "#F6F7FA", // Disabled
              },
              "&.MuiButton-sizeMedium": {
                height: "36px",
                padding: "8px 16px",
              },
              "&.MuiButton-sizeLarge": {
                height: "44px",
                padding: "12px 24px",
              },
              "&.MuiButton-sizeSmall": {
                height: "28px",
                padding: "4px 12px",
              },
            },
          },

          // Estilos para botones terciarios (text)
          "&.MuiButton-text": {
            color: "#F17DB2", // Color de letra activo
            boxShadow: "none",
            background: "none",
            borderRadius: 0,
            backgroundColor: "transparent",

            height: "28px",
            padding: "4px 12px",

            transition: "border-bottom 50ms ease",

            "&:hover": {
              borderBottom: "1px solid #EF60A3",
              backgroundColor: "transparent",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
            "&:active": {
              backgroundColor: "transparent",
            },
            "&.Mui-disabled": {
              color: "#8C9196", // Color de letra disabled
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // Estilos para el label en todos los estados
          "& label": {
            color: "#6C6D70",
          },
          // Estilos para el input en todos los estados
          "& .MuiInputBase-input": {
            color: "#6C6D70", // Color del texto en estado Active y Blocked
          },
          // Estilos para el fieldset en todos los estados
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px", // border-radius para todos los estados
            "& fieldset": {
              borderWidth: "1px",
              borderColor: "#E6E7E9", // Borde en estado Active y Typing
            },
            "&:hover fieldset": {
              borderColor: "#E6E7E9", // Borde en hover
            },
            "&.Mui-focused fieldset": {
              borderWidth: "1.5px",
              borderColor: "#EF60A3", // Borde en estado Focus
            },
            "& .MuiInputLabel-root.Mui-error": {
              color: "#ff8804",
            },
            "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
              border: "3px solid #ff8804",
            },
            "& .MuiFormHelperText-root.Mui-error": {
              color: "#ff8804",
            },

            "&.Mui-error": {
              "& .MuiInputBase-input": {
                color: "#2D2F31", // Color del texto en estado Error
              },
              "& fieldset": {
                borderWidth: "1.5px",
                borderColor: "#CF1E3C", // Borde en estado Error
              },
            },
            "&.Mui-disabled": {
              "& fieldset": {
                backgroundColor: "#E6E7E9", // Fondo en estado Blocked (Disabled)
                borderColor: "transparent",
              },
              "& .MuiInputBase-input": {
                color: "#6C6D70", // Color del texto en estado Disabled
              },
            },
          },
          // Cambios específicos de color del texto en los estados Typing y Value
          "&.Mui-focused .MuiInputBase-input": {
            color: "#253746", // Color del texto en estado Typing y Value
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          // Estilos para el label en todos los estados
          "& label": {
            color: "#6C6D70",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // border-radius para todos los estados
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1.5px",
            borderColor: "#EF60A3", // Borde en estado Focus
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E6E7E9", // Borde en hover
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1.5px",
            borderColor: "#CF1E3C", // Borde en estado Error
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            backgroundColor: "#E6E7E9", // Fondo en estado Disabled
            borderColor: "transparent",
          },
        },
        notchedOutline: {
          borderColor: "#E6E7E9", // Borde por defecto
        },
        input: {
          color: "#6C6D70", // Color del texto
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "#FBFBFB", // Fondo en estado Focus
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: "1px solid rgba(186, 186, 186, 0.20)",
          borderRadius: "16px",
          backgroundColor: "#FEFEFE",

          "&.MuiPopover-paper": {
            borderRadius: "4px",
          },
        },
      },
    },
  },
});

export default theme;
