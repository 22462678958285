import { Yup } from '../../utils/yup-locale';
import { ParkingVehicle } from '../parking';
import { ShuttlePassenger } from '../shuttle';

// ********************************************************************************
// Form for the checkout process, it is used to store the contact information of
// the user, along with information of the additional services that the user may
// have selected.
// NOTE: This is for the Contact Information tab in the checkout process only. This
//       form only purpose is to render and validate, payload construction is
//       delegated to the ShoppingCartContext.
// SEE: src/context/ShoppingCart/use-shopping-cart-context.tsx
export type CheckoutContactInformation = {
  name: string;
  lastName: string;
  nationality: string;
  email: string;
  confirmEmail: string;
  postalCode: string;
  phone: string;

  isSamePerson: boolean;
  nameContact: string;
  lastNameContact: string;
  emailContact: string;
};

export const DEFAULT_CHECKOUT_CONTACT_INFORMATION: CheckoutContactInformation = {
  name: '',
  lastName: '',
  nationality: '',
  email: '',
  confirmEmail: '',
  postalCode: '',
  phone: '',

  isSamePerson: false,
  nameContact: '',
  lastNameContact: '',
  emailContact: '',
};

// -- Schema --------------------------------------------------------------------
export const checkoutContactInformationSchema = Yup.object().shape({
  name: Yup.string().required(),
  lastName: Yup.string().required(),
  nationality: Yup.string().required(),
  email: Yup.string().email().required(),
  confirmEmail: Yup
    .string()
    .email()
    .oneOf([Yup.ref('email')], 'yup.string.emailMismatch')
    .required(),
  postalCode: Yup.string().required(),
  phone: Yup.string().required(),

  isSamePerson: Yup.boolean().required(),
  nameContact: Yup
    .string()
    .optional()
    .when('isSamePerson', {
      is: (val: boolean) => val === false,
      then: () => Yup.string().required(),
    }),
  lastNameContact: Yup
    .string()
    .optional()
    .when('isSamePerson', {
      is: (val: boolean) => val === false,
      then: () => Yup.string().required(),
    }),

  emailContact: Yup.string()
    .email()
    .optional()
    .when('isSamePerson', {
      is: (val: boolean) => val === false,
      then: () => Yup.string().email().required(),
    }),
});

export const shuttlePassengersSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
});

export const parkingVehicleSchema = Yup.object().shape({
  brand: Yup.string().required(),
  model: Yup.string().required(),
  year: Yup.string().required(),
  color: Yup.string().required(),
  vehicleLicense: Yup.string().required(),
  state: Yup.string().required(),
});