import React from 'react';

const FirebaseContext = React.createContext(null);

/**
 * HOC for firebase components
 * @param {function} Component - react component
 * @returns {function}
 */
export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
