import get from 'lodash.get';

/**
 * Helper function to determine if a given property exists
 * @param {Object} property Property being tested
 * @returns {boolean}
 */
export function exists(property) {
  if (typeof property !== 'undefined' && property) {
    return true;
  }
  return false;
}

/**
 * Helper that checks if the param is a valid function
 * @param {function} func - function to evaluate
 * @returns {boolean}
 */
export function isValidFunction(func) {
  return func && typeof func === 'function';
}

/**
 * Helper that checks if the param is a valid string
 * @param {string} str - string to evaluate
 * @returns {boolean}
 */
export function isValidString(str) {
  return str && typeof str === 'string';
}

/**
 * Helper to determine if array is valid and not valid
 * @param {array} array to be checked
 * @returns {boolean}
 */
export function isValidArray(array) {
  return exists(array) && Array.isArray(array) && array.length > 0;
}

/**
 * Helper that checks if the param is a valid object
 * @param {Object} obj - object to evaluate
 * @returns {boolean}
 */
export function isValidObject(obj) {
  return obj && typeof obj === 'object' && Object.keys(obj).length > 0;
}

/**
 * Performs comparison between two values to determine if they are
 * equivalent, if 'partial' is true performs compare no deep in objects/arrays.
 * @param {*} value The value to compare.
 * @param {*} other The other value to compare.
 * @param {boolean} shallow If true make a non-deep comparation in objects/arrays.
 * @returns {boolean} Returns `true` if the values are equivalent, else `false`.
 */
export function isEqual(value, other, shallow = false) {
  const dateTag = '[object Date]';
  const errorTag = '[object Error]';
  const self = {
    // For similar behavior of _.eq
    equal: (valA, valB) => {
      if (valA === valB) {
        return true;
      }
      return (
        typeof valA === 'number'
        && Number.isNaN(valA)
        && typeof valB === 'number'
        && Number.isNaN(valB)
      );
    },
    getTag: (valA, valB) => {
      const tagA = Object.prototype.toString.call(valA);
      const tagB = Object.prototype.toString.call(valB);
      if (tagA !== tagB) {
        return false;
      }
      return tagA;
    },
    equalByTag: (valA, valB) => {
      const tag = self.getTag(valA, valB);
      if (!tag) {
        return false;
      }
      switch (tag) {
        case dateTag:
          // Coerce dates to milliseconds.
          // Invalid dates are coerced to `NaN`.
          return self.equal(+valA, +valB);
        case errorTag:
          return valA.name === valB.name && valA.message === valB.message;
        default:
          return true;
      }
    }
  };

  if (self.equal(value, other)) {
    return true;
  } if (!self.equalByTag(value, other)) {
    return false;
  } if (
    value == null
    || other == null
    || typeof value !== 'object'
    || typeof other !== 'object'
  ) {
    return false;
  }

  const keysValue = Object.keys(value);
  const keysOther = Object.keys(other);

  if (keysValue.length !== keysOther.length) {
    return false;
  }

  for (let i = 0; i < keysValue.length; i += 1) {
    if (!Object.prototype.hasOwnProperty.call(other, keysValue[i])) {
      return false;
    }
    const valA = value[keysValue[i]];
    const valB = other[keysValue[i]];

    if (!self.equalByTag(valA, valB)) {
      return false;
    } if (!shallow && isValidObject(valA) && isValidObject(valB)) {
      if (!isEqual(valA, valB, shallow)) {
        return false;
      }
    } else if (!self.equal(valA, valB)) {
      return false;
    }
  }
  return true;
}

/**
 * Helper function to get key if exist otherwise returns undefined
 * @param {Object} obj The object to query
 * @param {(Array|string)} path The path of the property to get
 * @param {*} [defaultValue] The value returned for `undefined` resolved values.
 * @returns {*}
 */
export const getKey = get;

/**
 * Returns a cookie by name from document.cookie
 * @param {string} name cookie name
 * @returns {string}
 */
export function getCookie(name) {
  if (getKey(global, 'window.document')) {
    const cookies = `; ${document.cookie}`;
    const parts = cookies.split(`; ${name}=`);
    let cookie = '';
    if (parts.length === 2) {
      cookie = parts
        .pop()
        .split(';')
        .shift();
    }
    return cookie;
  }
  return null;
}

/**
 * sets a cookie by name for document.cookie
 * @param {string} name cookie name
 * @param {string} value for cookie
 * @param {string} days how many days before expire

 */
export function setCookie(name, value, days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  /* global document */
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

/**
 * checks if a provided url is absolute
 * @param {string} url to check
 * @returns {boolean}
 */
export function isAbsoluteUrl(url) {
  return /^[a-z][a-z0-9+.-]*:/.test(url);
}

/**
 * gets the correct locale link if a relative url is provided
 * @param {string} url to get
 * @param {string} locale of the page
 * @returns {string}
 */
export function getLink(url, locale) {
  return isAbsoluteUrl(url) ? url : `/${locale}${url}`;
}

/**
 * Capitalizes first letter of each word in a string
 * @param {string} str to capitalize
 * @returns {string}
 */
export function capitalize(str) {
  return str.trim().toLowerCase().replace(/\w\S*/g, w => (w.replace(/^\w/, c => c.toUpperCase())));
}
