export enum TicketCompany {
  Tijuana = 1,
  SanDiego = 2,
}

export interface TicketCompanyDetails {
  abbreviatedPlace: string;
  place: string;
}

const TICKET_COMPANY_MAP: Record<TicketCompany, TicketCompanyDetails> = {
  [TicketCompany.Tijuana]: { abbreviatedPlace: 'tij', place: 'Mexico' },
  [TicketCompany.SanDiego]: { abbreviatedPlace: 'tjx', place: 'USA' },
};

export const getTicketCompanyDetails = (travelDestination: TicketCompany) => TICKET_COMPANY_MAP[travelDestination];
