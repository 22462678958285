// @ts-check
import React, { useMemo, useState, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { DatePicker } from '@mui/x-date-pickers';

import { ShoppingCartContext } from './shopping-cart-context';
import { TicketCompany, getTicketCompanyDetails } from '../../domain/tickets';
import { ShuttleContactForm } from '../../features/Checkout/components/CheckoutContactInformation/shuttle-contact-form';

import '../../utils/yup-locale';
import { ParkingContactForm } from '../../features/Checkout/components/CheckoutContactInformation/parking-contact-form';
import { AssistedPassContactInfoForm } from '../../features/Checkout/components/CheckoutContactInformation/assisted-pass-contact-info-form';
import { UnaccompaniedMinorContactInfoForm } from '../../features/Checkout/components/CheckoutContactInformation/unaccompanied-minor-contact-info-form';
import { ServiceType } from '../../domain/common/service';
import { TravelClubContactForm } from '../../features/travel-club/components/contact-information';
import { getKey } from '../../legacy/helpers';

/**
 * @typedef {import('../../types/Ticket').SingleTicket} SingleTicket
 * @typedef {import('../../types/Ticket').GroupRate} GroupRate
 * @typedef {import('../../types/Ticket').PackageDeal} PackageDeal
 * @typedef {import('../../types/Parking').Parking} Parking
 * @typedef {import('../../types/Shuttle').Shuttle} Shuttle
 * @typedef {import('../../types/serviceType').ServiceType} ServiceType
 *
 * @typedef {import('./shopping-cart.context').ShoppingCart} ShoppingCart
 * @typedef {import('./shopping-cart.context').ShoppingCartContextType} ShoppingCartContextType
 */

/**
 * @type {ShoppingCart}
 */
let initialShoppingCart = {
  items: [],
  total: 0,
};

if (typeof window != 'undefined') {
  const localCart = window.localStorage.getItem('CBX_Cart_2');
  if (localCart) {
    initialShoppingCart = JSON.parse(localCart);
    initialShoppingCart.total = initialShoppingCart.items.reduce((total, item) => {
      return total + item.item.total;
    }, 0.0);
    initialShoppingCart.total = parseFloat(initialShoppingCart.total.toFixed(2));
  }
}
/**
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @returns {JSX.Element}
 */
export default function ShoppingCartProvider({ children }) {
  const { t } = useTranslation();
  /**
   * @type {[ShoppingCart , React.Dispatch<React.SetStateAction<ShoppingCart>>]}
   */
  const [cart, setCart] = useState(initialShoppingCart);
  const [appliedPromoCode, setAppliedPromoCode] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openShoppingCartAddServices, setOpenShoppingCartAddServices] = useState(false);
  const [infoComplete, setInfoComplete] = useState(initialShoppingCart.items.length > 0);

  useEffect(() => {
    setInfoComplete(checkContactInfo());
  }, [cart]);

  const calculateTotal = () => {
    return cart.items.reduce((accum, item) => accum + item.item.total, 0);
  };

  const openShoppingCart = () => {
    setOpenShoppingCartAddServices(false);
    setIsOpen(true);
  };

  const closeShoppingCart = () => {
    setOpenShoppingCartAddServices(false);
    setIsOpen(false);
  };

  /**
   * @param {ServiceType} type - The type of service
   * @param {SingleTicket | GroupRate | PackageDeal | Parking | Shuttle } service - The service to add to the cart
   * @returns {void}
   */
  const notifyAddToCart = (service, type) => {
    let product = analyticsProduct(service);
    if (!product) return;

    if (typeof window === 'undefined') return;
    if (!window.dataLayer) return;
    //if (!window.gtag) return;

    //window.gtag('event', 'add_to_cart', {
    //  currency: 'USD',
    //  value: product.price * product.quantity,
    //  items: [product],
    //});

    console.log('sending datalayer');
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currency: 'USD',
        value: product.price * product.quantity,
        items: [product]
      }
    })
  };

  const analyticsProduct = (service) => {
    if (service.type === 'ticket') {
      return {
        item_name: service.tixProd.DsPublicAlias,
        item_id: service.tixProd.IdProduct,
        price: service.tixProd.Amount,
        quantity: service.tixProd.NuProdQuant,
      };
    }
    if (service.type === 'black-cars') {
      return {
        item_name: service.tixProduct.DsPublicAlias,
        item_id: service.tixProduct.IdProduct,
        price: service.tixProduct.Amount,
      };
    }
    if (service.type === 'parking') {
      return {
        item_name: `Parking ${service.location}`,
        item_id: `Parking ${service.location}`,
        price: service.customer_paid,
        quantity: 1,
      };
    }
    if (service.type === 'wheels') {
      return {
        item_name: `Wheels ${service.vehicle} ${service.zone}`,
        item_id: `Wheels_${service.vehicle}_${service.zone}`,
        price: service.Amount,
        quantity: 1,
      };
    }
    if (service.type === 'shuttle') {
      return {
        item_name: `Shuttle ${service.originText} to ${service.destinationText}`,
        item_id: `Shuttle ${service.provider}`,
        price: service.price,
        quantity: service.passengersQ,
      };
    }
    return null;
  };

  /**
   * Runs notifications when purchase is successful
   * @param {transactionId} string - The type of service
   * @returns {void}
   */
  const notifySuccessPurchase = (transactionId) => {
    const actionField = {
      currency: 'USD',
      transaction_id: transactionId,
      value: calculateTotal(),
    };

    if (typeof window === 'undefined') return;
    if (!window.dataLayer) return;
    //if (!window.gtag) return;

    //window.gtag('event', 'purchase', {
    //  ...actionField,
    //  items: cart.items.map((service) => analyticsProduct(service.item)),
    //});

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        ...actionField,
        items: cart.items.map((service) => analyticsProduct(service.item)),
      }
    });
  };

  const cartHasDifferentDates = () => {
    const tickets = cart.items.filter((item) => item.item.type === 'ticket');
    const services = cart.items.filter((item) => item.item.type !== 'ticket');

    if (tickets.length === 0) return false;
    if (services.length === 0) return false;

    const ticketDates = {};
    tickets.forEach((item) => {
      const ticket = item.item;
      ticketDates[new Date(ticket.departureDate).toISOString().substring(0, 10)] = true;
      if (ticket.returnDate) {
        ticketDates[new Date(ticket.returnDate).toISOString().substring(0, 10)] = true;
      }
    });

    return !services.every((item) => {
      const service = item.item;
      if (service.type === 'parking') {
        const startDate = service.start_time.substring(0, 10);
        const endDate = service.end_time.substring(0, 10);

        const matched = ticketDates[startDate] || ticketDates[endDate];
        const res = !!matched;

        return res;
      } else if (service.type === 'shuttle') {
        const departDate = service.departureDate;

        const matched = ticketDates[departDate];
        const res = !!matched;

        return res;
      }
      return true;
    });
  };

  /**
   * @param {ServiceType} type - The type of service
   * @param {SingleTicket | GroupRate | PackageDeal | Parking | Shuttle } service - The service to add to the cart
   * @returns {void}
   */
  const addServiceToCart = (service, type) => {
    // Validate if services that can only be added once are already in the cart
    // Travel Club
    if (type === ServiceType.TravelClub) {
      const isTravelClubInCart = cart.items.some((item) => item.type === ServiceType.TravelClub);
      if (isTravelClubInCart) return /*nothing else to do*/;
    }

    const serviceId = crypto.randomUUID();
    if (!service.id) {
      service.id = serviceId;
    }
    setCart((prevCart) => {
      const newCart = { ...prevCart };

      newCart.items.push({ type, item: service, quantity: service.quantity });

      newCart.total = newCart.items.reduce((total, item) => {
        return total + item.item.total;
      }, 0.0);
      newCart.total = parseFloat(newCart.total.toFixed(2));

      setInfoComplete(checkContactInfo());
      window.localStorage.setItem('CBX_Cart_2', JSON.stringify(newCart));

      notifyAddToCart(service, type);

      return newCart;
    });
  };

  /**
   * @param {SingleTicket | GroupRate | PackageDeal | Parking | Shuttle}
   * service
   */
  const removeServiceFromCart = (service) => {
    setCart((prevCart) => {
      const newCart = { ...prevCart };

      const index = newCart.items.findIndex((item) => item.item.id === service.id);

      if (index > -1) {
        newCart.items.splice(index, 1);
        newCart.total -= service.total;
      }

      newCart.total = newCart.items.reduce((total, item) => {
        return total + item.item.total;
      }, 0.0);
      newCart.total = parseFloat(newCart.total.toFixed(2));

      setInfoComplete(checkContactInfo());
      window.localStorage.setItem('CBX_Cart_2', JSON.stringify(newCart));

      return newCart;
    });
  };

  /**
   */
  const buildCheckoutCart = () => {
    const products = cart.items.map((product) => {
      if (product.type === 'parking') {
        const vehicle = product.item;
        return {
          ...vehicle,
          type: 'parking',
          location: vehicle.lot,
          vehicleDescription: `${vehicle.brand} ${vehicle.model} ${vehicle.year} color ${vehicle.color}`,
        };
      }
      if (product.type === 'donation') {
        return {
          ...product.item.tixProd,
        };
      }
      if (product.type === 'wheels') {
        return {
          ...product.item,
        };
      }
      if (product.type === 'black-cars') {
        return {
          ...product.item.tixProduct,
          VehicleType: product.item.tixProduct.vehicleType,
          ServiceType: product.item.filters.serviceType,
          LicensePlate: product.item.filters.licensePlate,
          DriverName: product.item.filters.driverName,
          DriverPhone: product.item.filters.driverTel,
          CompanyName: product.item.filters.companyName,
          Tcppsc: product.item.filters.tcppsc,
          DtStart: product.item.DtStart,
          ticketDate: product.item.ticketDate,
          DtTime: product.item.filters.time,
          type: product.item.type
        }
      }
      if (product.type === 'SingleTicket') {
        const item = product.item;

        let contactInfoProps = {};

        if (!!product.item.assisted_data) {
          /** Add contact info if assisted_data prop exist */
          contactInfoProps = { assisted_data: product.item.assisted_data };
        }

        // If the product has a discount, apply it to the Amount
        // NOTE: Doing it at checkout time to avoid losing the original price when
        //       applying a promo code
        if (product.item.tixProd.Discount > 0) product.item.tixProd.Amount = product.item.tixProd.DiscountAmount;

        return {
          passengers: item.tixProd.NuProdQuant,
          ticketType: 'single',
          travelDestination: item.origin === 'Mexico' ? 'TIJ > TJX' : 'TJX > TIJ',
          origin:
            item.origin === 'Mexico'
              ? getTicketCompanyDetails(TicketCompany.Tijuana)
              : getTicketCompanyDetails(TicketCompany.SanDiego),
          destination:
            item.origin === 'Mexico'
              ? getTicketCompanyDetails(TicketCompany.SanDiego)
              : getTicketCompanyDetails(TicketCompany.Tijuana),
          travelType: item.tripType,
          travelPurpose: item.visitReason,
          ticketDate: new Date(item.departureDate).toISOString().substring(0, 10),
          returnDate: item.returnDate
            ? new Date(item.returnDate).toISOString().substring(0, 10)
            : item.returnDate?.toISOString().substring(0, 10),
          type: 'ticket',
          ...item.tixProd,
          DtStart: new Date(item.departureDate).toISOString().substring(0, 10),
          DtEnd: item.returnDate
            ? new Date(item.returnDate).toISOString().substring(0, 10)
            : item.returnDate?.toISOString().substring(0, 10),
          flightDate: new Date(item.departureDate).toISOString().substring(0, 10),

          ...contactInfoProps,
        };
      }
      if (product.type === 'shuttle') {
        return {
          ...product.item,
          Amount: product.item.price,
          NuProdQuant: product.item.passengersQ,
        };
      }

      if (product.type === ServiceType.TravelClub) {
        const { isSamePerson, ...member } = product.item.member;
        // means that the member is the same person that is logged in
        if (isSamePerson) {
          const contactInformation = window.localStorage.getItem('CBX_Contact_Information');
          if (contactInformation) {
            const parsedContactInformation = JSON.parse(contactInformation);
            member.email = parsedContactInformation.email;
            member.firstName = parsedContactInformation.name;
            member.lastName = parsedContactInformation.lastName;
          }
          // CHECK: return an error?
          // else {}
        }
        return {
          type: product.type,
          Amount: product.item.total,
          NuProdQuant: product.item.quantity,
          member,
          plan: product.item.plan.key,
        };
      }
    });
    return {
      TotalAmount: calculateTotal(),
      Products: products,
    };
  };

  const checkContactInfo = () => {
    const itemStates = cart.items.map((product) => {
      switch (product.type) {
        case 'SingleTicket':
          if (!product.item.assisted_data) return true; /** There is no validation for Adult Tickets */

          if (!product.item.assisted_data.tutorInformation) return true; /** Assisted Pass form is optional */

          const passsenger = product.item.assisted_data.passengerInformation;

          if (passsenger.age === null) return true;

          if (passsenger.age < 13 /** MIN AGE */ || passsenger.age > 17 /** MAX AGE */)
            return false; /** Validate unaccompanied minor age */

          return true;
        case 'parking': {
          const fields = ['brand', 'model', 'year', 'color', 'vehicleLicense', 'state'];
          for (const field of fields) {
            if (!product.item[field]) return false;
          }
          return true;
        }
        case 'shuttle': {
          const fields = ['firstName', 'lastName', 'email'];
          if (!product.item.passengersInfo) {
            product.item.passengersInfo = [];
            for (let i = 0; i < product.item.passengersQ; i++) {
              product.item.passengersInfo.push({
                email: '',
                firstName: '',
                lastName: '',
              });
            }
          }
          const passengersOK = product.item.passengersInfo.map((passenger) => {
            for (const field of fields) {
              if (!passenger[field]) return false;
            }
            return true;
          });
          return passengersOK.filter((passenger) => passenger).length == passengersOK.length;
        }
        case ServiceType.TravelClub: {
          // If the member is the same person, we don't need to validate the fields
          // as they're already validated in the contact information form

          if (!product.item.member.acceptRegion) return false;
          if (product.item.member.isSamePerson) return true;

          const fields = ['firstName', 'lastName', 'email'];
          for (const field of fields) {
            if (!product.item.member[field]) return false;
          }
          return true;
        }
        default:
          return true;
      }
    });
    const valid = itemStates.filter((item) => item === true).length === itemStates.length;
    return valid;
  };
  /**
   */
  const buildContactInfo = ({ user }) => {
    return (
      <>
        {cart.items.map((product) => {
          switch (product.type) {
            case 'SingleTicket':
              if (!product.item.assisted_data) {
                return <></>;
              }

              const updateFlightInfo = (field, value) => {
                product.item.assisted_data.flightInformation[field] = value;
              };

              const updatePassengerInfo = (field, value) => {
                product.item.assisted_data.passengerInformation[field] = value;
              };

              if (!!product.item.assisted_data.tutorInformation) {
                const updateTutorInfo = (field, value) => {
                  product.item.assisted_data.tutorInformation[field] = value;
                };

                return (
                  <UnaccompaniedMinorContactInfoForm
                    ticket={product.item}
                    onFlightChange={(key, value) => {
                      updateFlightInfo(key, value);
                      setInfoComplete(checkContactInfo());
                    }}
                    onPassengerChange={(key, value) => {
                      updatePassengerInfo(key, value);
                      setInfoComplete(checkContactInfo());
                    }}
                    onTutorChange={(key, value) => {
                      updateTutorInfo(key, value);
                      setInfoComplete(checkContactInfo());
                    }}
                  />
                );
              }

              return (
                <AssistedPassContactInfoForm
                  ticket={product.item}
                  onFlightChange={(key, value) => {
                    updateFlightInfo(key, value);
                    setInfoComplete(checkContactInfo());
                  }}
                  onPassengerChange={(key, value) => {
                    updatePassengerInfo(key, value);
                    setInfoComplete(checkContactInfo());
                  }}
                />
              );
            case 'parking':
              const updateItem = (field, value) => {
                product.item[field] = value;
              };
              return (
                <ParkingContactForm
                  cartParkingItem={product.item}
                  handleCartChange={(k, v) => {
                    updateItem(k, v);
                    setInfoComplete(checkContactInfo());
                  }}
                />
              );
            case 'shuttle':
              let firstPassengerDefaultValues = { email: '', firstName: '', lastName: '' };

              /**
               * Set logged in user as first passenger default values
               */
              if (user) {
                firstPassengerDefaultValues = { email: user.email, firstName: user.name, lastName: user.lastName };
              }

              const contactInformation = window.localStorage.getItem('CBX_Contact_Information');
              /**
               * Set contact info data in localstorage as first passenger default values
               */
              if (contactInformation) {
                const parsedContactInformation = JSON.parse(contactInformation);
                firstPassengerDefaultValues = {
                  email: parsedContactInformation.email,
                  firstName: parsedContactInformation.name,
                  lastName: parsedContactInformation.lastName,
                };
              }

              if (!product.item.passengersInfo /** There are not passengers yet */) {
                product.item.passengersInfo = [];
                for (let i = 0; i < product.item.passengersQ; i++) {
                  // If it is the first passenger
                  if (i === 0) {
                    /**
                     * First passenger default values can be:
                     * - All fields empty - If there is no logged in user or contact info data in localstorage
                     * - Pre-filled with logged in user - If there is logged in user and there is no contact info data in localstorage
                     * - Pre-filled with contact info - If there is contact info data in localstorage
                     */
                    product.item.passengersInfo.push(firstPassengerDefaultValues);
                  } else {
                    product.item.passengersInfo.push({ email: '', firstName: '', lastName: '' });
                  }
                }
              } else {
                /**
                 * Set first passenger. In this point the first passenger object already exists.
                 *
                 * First passenger default values can be:
                 * - Pre-filled with logged in user - If there is logged in user and there is no contact info data in localstorage
                 * - Pre-filled with contact info - If there is contact info data in localstorage
                 */
                const firstPassenger = product.item.passengersInfo[0];
                if (!firstPassenger.email) {
                  product.item.passengersInfo[0].email = firstPassengerDefaultValues.email;
                }

                if (!firstPassenger.firstName) {
                  product.item.passengersInfo[0].firstName = firstPassengerDefaultValues.firstName;
                }

                if (!firstPassenger.lastName) {
                  product.item.passengersInfo[0].lastName = firstPassengerDefaultValues.lastName;
                }
              }

              return (
                <Stack direction="column" gap={3} mt={1}>
                  <Stack direction="column" gap="8px">
                    <Typography variant="body1" color="secondary" fontWeight={700}>
                      Shuttle {product.item.departureDate}
                    </Typography>
                    <Typography variant="body2" color="secondary">
                      {product.item.originText} - {product.item.destinationText}
                    </Typography>
                  </Stack>
                  {product.item.passengersInfo.map((pInfo, index) => {
                    const updateItem = (field, value) => {
                      product.item.passengersInfo[index][field] = value;
                    };
                    //TODO: probar agregar el shuttleform dentro de un memo, o en su defecto poner memos en ShuttleForm para que solo renderee los elementos que se actualizan.
                    return (
                      <ShuttleContactForm
                        index={index + 1}
                        passengersQ={product.item.passengersQ}
                        cartShuttleItem={product.item.passengersInfo[index]}
                        handleCartChange={(k, v) => {
                          updateItem(k, v);
                          setInfoComplete(checkContactInfo());
                        }}
                      />
                    );
                  })}
                </Stack>
              );

            case ServiceType.TravelClub: {
              const updateItem = (field: string, value: any) => {
                product.item.member[field] = value;
              };
              return (
                <TravelClubContactForm
                  travelClub={product.item}
                  handleCartChange={(k, v) => {
                    updateItem(k, v);
                    setInfoComplete(checkContactInfo());
                  }}
                />
              );
            }
            default:
              return <></>;
          }
        })}
      </>
    );
  };

  /**
   * apply a coupon to current cart
   * @param {Object} promoCodeData - promo code data
   * @param {Object} cart - cart items
   */
  // FIXME: Type this function to specify the type of the promoCodeData parameter
  const applyPromoCode = (promoCodeData) => {
    if (appliedPromoCode) return;

    const discountType = promoCodeData.IdPromoCodeType;
    const products = promoCodeData.lsProducts;
    const MaxProducts = promoCodeData.NuMaxProductPurchase;

    // sort cart by amount
    let newCart = cart.items.sort((a, b) => parseFloat(b.Amount) - parseFloat(a.Amount));
    const isValidArray = (arr: any) => Array.isArray(arr);

    if (discountType === 1 /* PERCENTAGE */) {
      const dontHaveDiscount = products.filter((p) => getKey(p, 'BoExcluded') === true);
      let keysWithoutDiscount = [];
      if (isValidArray(dontHaveDiscount)) {
        keysWithoutDiscount = dontHaveDiscount.map((p) => p.IdProduct);
      }

      let prodsApplied = 0;
      const discountPercentage = parseFloat(getKey(promoCodeData, 'Discount', 0));
      const missingProducts = { flag: false };

      newCart = newCart.map((p) => {
        if (p.type !== `SingleTicket`) return p /*no need to apply discount*/;
        const product = p.item.tixProd;

        const newProduct = { ...product };
        const currentProductID = product.IdProduct;
        const price = product.Amount as number;

        if (isValidArray(keysWithoutDiscount) && keysWithoutDiscount.includes(currentProductID)) return p;

        // Setting the promo code to the cart in here so it is available for the checkout
        // process, applying it until a discount is applied to the cart
        setAppliedPromoCode(promoCodeData);
        const toApply = Math.min(product.NuProdQuant, MaxProducts - prodsApplied);

        let unitDiscount = (price * (discountPercentage / 100)).toFixed(2);
        newProduct.Discount = toApply * unitDiscount;
        newProduct.DiscountAmount = price - unitDiscount;

        // Split the product if the discount is applied to a subset of the products
        const prodsLeft = MaxProducts - prodsApplied;
        if (prodsLeft > 0 && prodsLeft < product.NuProdQuant) {
          missingProducts.flag = true;
          const quantity = product.NuProdQuant - (MaxProducts - prodsApplied);
          missingProducts.product = {
            ...p,
            item: {
              ...p.item,
              tixProd: {
                ...product,
                NuProdQuant: quantity,
              },
              price: newProduct.Amount,
              total: newProduct.Amount * quantity,
              quantity,
            },
            quantity,
          };
          newProduct.NuProdQuant = MaxProducts - prodsApplied;
        }
        prodsApplied += toApply;

        return {
          ...p,
          item: {
            ...p.item,
            price: newProduct.DiscountAmount,
            total: newProduct.Amount * newProduct.NuProdQuant - newProduct.Discount,
            quantity: newProduct.NuProdQuant,
            tixProd: newProduct,
          },
          quantity: newProduct.NuProdQuant,
        };
      });

      if (missingProducts.flag) {
        newCart.push(missingProducts.product);
      }

      setCart((prevCart) => ({
        ...prevCart,
        items: [...newCart],
        // recalculate total
        total: parseFloat(
          newCart
            .reduce((total, item) => {
              return total + item.item.total;
            }, 0.0)
            .toFixed(2),
        ),
      }));

      // set promo code
    } else if (discountType === 2 /* FIXED AMOUNT */) {
      const hasSomeDiscount = products.filter((p) => getKey(p, 'BoExcluded') === false);

      if (hasSomeDiscount.length > 0) {
        const productDiscount = hasSomeDiscount.reduce(
          (result, p) => ({
            [`discountPrice${p.IdProduct}`]: p.DcSpecialPrice,
            ...result,
          }),
          {},
        );
        const keysWithDiscount = hasSomeDiscount.map((p) => p.IdProduct);
        let prodsApplied = 0;
        const missingProducts = {
          flag: false,
        };

        newCart = newCart.map((p) => {
          const product = p.item.tixProd;

          const currentProductID = product.IdProduct;
          const price = product.Amount;
          const newProduct = { ...product };

          if (!keysWithDiscount.includes(currentProductID)) return p /*no need to apply discount*/;

          // Setting the promo code to the cart in here so it is available for the checkout
          // process, applying it until a discount is applied to the cart
          setAppliedPromoCode(promoCodeData);
          const toApply = Math.min(product.NuProdQuant, MaxProducts - prodsApplied);

          const priceDifference = price - productDiscount[`discountPrice${currentProductID}`];
          if (priceDifference <= 0) return product; /*no need to apply discount*/

          newProduct.Discount = toApply * priceDifference;
          newProduct.DiscountAmount = productDiscount[`discountPrice${currentProductID}`];

          // Split the product if the discount is applied to a subset of the products
          const prodsLeft = MaxProducts - prodsApplied;
          if (prodsLeft > 0 && prodsLeft < product.NuProdQuant) {
            missingProducts.flag = true;
            const quantity = product.NuProdQuant - (MaxProducts - prodsApplied);

            missingProducts.product = {
              ...p,
              item: {
                ...p.item,
                tixProd: {
                  ...product,
                  NuProdQuant: quantity,
                },
                price: newProduct.Amount,
                total: newProduct.Amount * quantity,
                quantity,
              },
              quantity,
            };

            newProduct.NuProdQuant = MaxProducts - prodsApplied;
          }
          prodsApplied += toApply;

          return {
            ...p,
            item: {
              ...p.item,
              price: newProduct.DiscountAmount,
              total: newProduct.Amount * newProduct.NuProdQuant - newProduct.Discount,
              quantity: newProduct.NuProdQuant,
              tixProd: newProduct,
            },
            quantity: newProduct.NuProdQuant,
          };
        });

        if (missingProducts.flag) {
          newCart.push(missingProducts.product);
        }

        setCart((prevCart) => ({
          ...prevCart,
          items: [...newCart],
          // recalculate total
          total: parseFloat(
            newCart
              .reduce((total, item) => {
                return total + item.item.total;
              }, 0.0)
              .toFixed(2),
          ),
        }));
      }
    }
  };

  // After a promo code is applied, the cart can be updated to remove the promo code
  // but if the promo code separated the cart into two different products, they
  // won't be merged again.
  const removePromoCode = () => {
    setAppliedPromoCode(null);
    setCart((prevCart) => {
      const newCart = { ...prevCart };

      newCart.items = newCart.items.map((item) => {
        if (item.item?.tixProd && item.item?.tixProd?.Discount > 0) {
          item.item.tixProd.Discount = 0;
          item.item.tixProd.DiscountAmount = 0;
          item.item.total = item.item.tixProd.Amount * item.item.tixProd.NuProdQuant;
        }
        return item;
      });

      newCart.total = newCart.items.reduce((total, item) => {
        return total + item.item.total;
      }, 0.0);
      newCart.total = parseFloat(newCart.total.toFixed(2));

      return newCart;
    });
  };

  /**
   * @type {ShoppingCartContextType}
   * @description Memoize the value to avoid unnecessary re-renders
   */
  const memoizedValue = useMemo(
    () => ({
      cart,
      isOpen,
      openShoppingCartAddServices,
      openShoppingCart,
      setOpenShoppingCartAddServices,
      closeShoppingCart,
      addServiceToCart,
      removeServiceFromCart,
      buildCheckoutCart,
      buildContactInfo,
      checkContactInfo,
      infoComplete,
      notifySuccessPurchase,
      cartHasDifferentDates,
      calculateTotal,
      appliedPromoCode,
      applyPromoCode,
      removePromoCode,
    }),
    [isOpen, cart, openShoppingCartAddServices, infoComplete, cartHasDifferentDates, appliedPromoCode],
  );

  return <ShoppingCartContext.Provider value={memoizedValue}>{children}</ShoppingCartContext.Provider>;
}
