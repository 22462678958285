import React from "react";
import { Typography } from "@mui/material";

// FIXME: Change the component structure to a functional component
export default function TypographyLink({
  href = '',
  bold = false,
  children = <></>,
  underline = false,
  ...props
}) {
  const handleClick = () => {
    window.open(href, "_blank");
  };

  return (
    <Typography
      sx={{
        cursor: "pointer",
        fontSize: "inherit",
        fontWeight: bold ? "bold" : "normal",
        textDecoration: underline ? "underline" : "none",
      }}
      onClick={handleClick}
      component="span"
      {...props}
    >
      {children}
    </Typography>
  );
}
