export interface AssistedTicketPassenger {
  age: number | null;
  firstName: string;
  gender: string;
  lastName: string;
  nationality: string;
  telephone: string;
}

export const DEFAULT_ASSISTED_TICKET_PASSENGER: AssistedTicketPassenger = {
  age: null,
  firstName: '',
  gender: '',
  lastName: '',
  nationality: '',
  telephone: '',
};

export interface AssistedTicketFlight {
  airline: string;
  date: string;
  destination: string;
  flightNumber: string;
  origin: string;
}

export const DEFAULT_ASSISTED_TICKET_FLIGHT: AssistedTicketFlight = {
  airline: '',
  date: '',
  destination: '',
  flightNumber: '',
  origin: '',
};
