import * as Yup from 'yup';

// ********************************************************************************
// Locale strings for Yup validation, this must be paired with the `t` function
// provided by the i18n library.
// IMPORTANT: Import this file in the root of the application to set the locale for
//            Yup.
// SEE: https://github.com/LoicMahieu/yup-locales/blob/master/src/locales/es.ts
const yupLocale: Yup.LocaleObject = {
  mixed: {
    required: 'yup.mixed.required',
  },
  string: {
    min: 'yup.string.min',
    max: 'yup.string.max',
    matches: 'yup.string.matches',
    email: 'yup.string.email',
  },
  number: {
    min: 'yup.number.min',
    max: 'yup.number.max',
    lessThan: 'yup.number.lessThan',
    moreThan: 'yup.number.moreThan',
    positive: 'yup.number.positive',
    negative: 'yup.number.negative',
    integer: 'yup.number.integer',
  },
};

Yup.setLocale(yupLocale);

export { Yup };


