export enum TicketSeason {
  Low = 'low',
  Regular = 'regular',
  High = 'high',
}

export const END_OF_MONTH = null;

const TICKET_SEASONS_BY_MONTHS = {
  0 /** January */: {
    [TicketSeason.High]: [{ from: 1, to: 16 }],
    [TicketSeason.Low]: [{ from: 17, to: 30 }],
    [TicketSeason.Regular]: [{ from: 31, to: END_OF_MONTH }],
  },
  1 /** February */: {
    [TicketSeason.Regular]: [
      { from: 1, to: 6 },
      { from: 14, to: 20 },
    ],
    [TicketSeason.Low]: [
      { from: 7, to: 13 },
      { from: 21, to: END_OF_MONTH },
    ],
  },
  2 /** March */: {
    [TicketSeason.Low]: [{ from: 1, to: 12 }],
    [TicketSeason.Regular]: [{ from: 13, to: END_OF_MONTH }],
  },
  3 /** April */: {
    [TicketSeason.Regular]: [{ from: 1, to: 9 }],
    [TicketSeason.Low]: [{ from: 10, to: END_OF_MONTH }],
  },
  4 /** May */: {
    [TicketSeason.Low]: [{ from: 1, to: 21 }],
    [TicketSeason.Regular]: [{ from: 22, to: END_OF_MONTH }],
  },
  5 /** June */: {
    [TicketSeason.Regular]: [{ from: 1, to: END_OF_MONTH }],
  },
  6 /** July */: {
    [TicketSeason.Regular]: [{ from: 1, to: END_OF_MONTH }],
  },
  7 /** August */: {
    [TicketSeason.Regular]: [
      { from: 1, to: 13 },
      { from: 28, to: END_OF_MONTH },
    ],
    [TicketSeason.Low]: [{ from: 14, to: 27 }],
  },
  8 /** September */: {
    [TicketSeason.Regular]: [
      { from: 1, to: 3 },
      { from: 11, to: 17 },
    ],
    [TicketSeason.Low]: [
      { from: 4, to: 10 },
      { from: 18, to: END_OF_MONTH },
    ],
  },
  9 /** October */: {
    [TicketSeason.Low]: [{ from: 1, to: END_OF_MONTH }],
  },
  10 /** November */: {
    [TicketSeason.Low]: [{ from: 1, to: 19 }],
    [TicketSeason.High]: [{ from: 20, to: END_OF_MONTH }],
  },
  11 /** December */: {
    [TicketSeason.High]: [
      { from: 1, to: 3 },
      { from: 11, to: END_OF_MONTH },
    ],
    [TicketSeason.Regular]: [{ from: 4, to: 10 }],
  },
};

export const getTicketSeasonsByMonth = (month: number) => TICKET_SEASONS_BY_MONTHS[month];
