import React, { ChangeEvent } from 'react';

import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';

// ********************************************************************************
// Controlled input component for forms, can be used with react-hook-form or
// standalone. Only essential props are exposed, but it accepts all TextFieldProps
// for more advanced usage.
export type FormTextFieldProps = {
  name: string;
  label: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
} & TextFieldProps;
export const FormTextField = ({ label, value, name, onChange, ...props }: FormTextFieldProps) => {
  // == Render ====================================================================
  return (
    <Stack direction="column" gap="8px">
      <Typography variant="body2" color="secondary" fontWeight={700}>
        {label}
      </Typography>
      <TextField name={name} value={value} onChange={onChange} variant="outlined" fullWidth {...props} />
    </Stack>
  );
};
