import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs, { Dayjs } from 'dayjs';
import { AssistedTicketFlight } from '../../../../domain/tickets';
import { Yup } from '../../../../utils/yup-locale';
import { FormSelectController } from './components/select-controller';
import { FormTextFieldController } from './components/text-field-controller';

interface Props {
  airlines: readonly string[];
  flight: AssistedTicketFlight;
  validationSchema: Yup.ObjectSchema<Partial<AssistedTicketFlight>>;
  onChange: (key: keyof AssistedTicketFlight, value: string) => void;
}

export const AssistedTicketFlightForm = ({ airlines, flight, validationSchema, onChange }: Props) => {
  const { t } = useTranslation();

  const form = useForm({ mode: 'all', defaultValues: flight, resolver: yupResolver(validationSchema) });

  const handleDateChange = (onFormChange: (date: string) => void) => (date: Dayjs | null) => {
    if (!date) return;
    if (!date.isValid()) return;

    const formattedDate = date.format('YYYY-MM-DD');
    onFormChange(formattedDate);

    onChange('date', formattedDate);
  };

  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={3}>
        <Typography variant="body1" color="secondary" fontWeight={700}>
          {t('checkout_contact_information.flight_information.title')}
        </Typography>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={4}>
            <Stack direction="column" gap="8px">
              <Typography variant="body2" color="secondary" fontWeight={700}>
                {t('checkout_contact_information.flight_information.inputs.date')}
              </Typography>
              <Controller
                control={form.control}
                name="date"
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    value={field.value ? dayjs(field.value, 'YYYY-MM-DD') : null}
                    variant="outlined"
                    placeholder={t('checkout_contact_information.flight_information.inputs.date_placeholder')}
                    fullWidth
                    onChange={handleDateChange(field.onChange)}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="flightNumber"
              label={t('checkout_contact_information.flight_information.inputs.flight_number')}
              placeholder={t('checkout_contact_information.flight_information.inputs.flight_number_placeholder')}
              onChange={(value) => onChange('flightNumber', value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormSelectController
              name="airline"
              label={t('checkout_contact_information.flight_information.inputs.airline')}
              placeholder={t('checkout_contact_information.flight_information.inputs.airline_placeholder')}
              withTitle
              options={airlines.map((airline) => ({ value: airline, label: airline }))}
              onChange={(event) => {
                onChange('airline', event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="origin"
              disabled={flight.origin === 'TIJ-TJX'}
              label={t('checkout_contact_information.flight_information.inputs.from')}
              placeholder={t('checkout_contact_information.flight_information.inputs.from_placeholder')}
              onChange={(value) => onChange('origin', value)}
              sx={{ '& .MuiOutlinedInput-notchedOutline': { backgroundColor: 'transparent !important' } }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="destination"
              disabled={flight.destination === 'TIJ-TJX'}
              label={t('checkout_contact_information.flight_information.inputs.to')}
              placeholder={t('checkout_contact_information.flight_information.inputs.to_placeholder')}
              onChange={(value) => onChange('destination', value)}
              sx={{ '& .MuiOutlinedInput-notchedOutline': { backgroundColor: 'transparent !important' } }}
            />
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  );
};
