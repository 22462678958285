// ********************************************************************************
export enum ServiceType {
  Wheels = 'wheels',
  Parking = 'parking',
  TravelClub = 'travelClub',
  Shuttle = 'shuttle',
  BlackCars = 'black-cars'
  // Add more service types here
}

// ................................................................................
interface IService {
  type: ServiceType;
  quantity: number;
  total: number;
}
export abstract class Service implements IService {
  // ==============================================================================
  public readonly type: ServiceType;
  public readonly quantity: number;
  public readonly total: number;

  // == Lifecycle =================================================================
  constructor({ type, quantity, total }: IService) {
    this.type = type;
    this.quantity = quantity;
    this.total = total;
  }
}
