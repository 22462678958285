export enum TicketType {
  AdultOneWay = 1,
  AdultRoundTrip = 2,
  Family = 3,
  UnaccompaniedMinor = 7,
  AsistedPass = 8,
}

export const isAdultTicket = (
  ticketType: TicketType,
): ticketType is TicketType.AdultOneWay | TicketType.AdultRoundTrip =>
  ticketType === TicketType.AdultOneWay || ticketType === TicketType.AdultRoundTrip;

export const isFamilyTrip = (ticketType: TicketType): ticketType is TicketType.Family =>
  ticketType === TicketType.Family;

export const isAssistedPassTicket = (ticketType: TicketType): ticketType is TicketType.AsistedPass =>
  ticketType === TicketType.AsistedPass;

export const isUnaccompaniedMinorTicket = (ticketType: TicketType): ticketType is TicketType.UnaccompaniedMinor =>
  ticketType === TicketType.UnaccompaniedMinor;
