import React from 'react';

import { Grid, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { parkingVehicleSchema } from '../../../../domain/checkout';
import { Parking, ParkingVehicle } from '../../../../domain/parking';
import { FormTextFieldController } from './components/text-field-controller';

// ********************************************************************************
type Props = {
  cartParkingItem: Parking;
  handleCartChange: (key: string, val: string) => void;
};
export const ParkingContactForm: React.FC<Props> = ({ cartParkingItem, handleCartChange }) => {
  const { t } = useTranslation();

  // == State ===============================================================
  const form = useForm<ParkingVehicle>({
    mode: 'all',
    defaultValues: {
      brand: cartParkingItem.brand || '',
      model: cartParkingItem.model || '',
      year: cartParkingItem.year || '',
      color: cartParkingItem.color || '',
      vehicleLicense: cartParkingItem.vehicleLicense || '',
      state: cartParkingItem.state || '',
    },
    resolver: yupResolver(parkingVehicleSchema),
  });

  const onItemUpdate = (key: string, val: string) => {
    handleCartChange(key, val);
  };

  // == Render ==============================================================
  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={3} mt={1}>
        <Stack direction="column" gap="8px">
          <Typography variant="body1" color="secondary" fontWeight={700}>
            {t('checkout_contact_information.parking.title')} {t('parking_summary.title')} {cartParkingItem.location}
          </Typography>
          <Typography variant="body2" color="secondary">
            {cartParkingItem.start_time.replace('T', ' @ ')} - {cartParkingItem.end_time.replace('T', ' @ ')}
          </Typography>
          <Typography variant="body2" color="secondary">
            {t('checkout_contact_information.parking.reservation_not_subject_to_vehicle')}
          </Typography>
        </Stack>

        <Stack direction="column" gap={3}>
          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="brand"
                onChange={(val) => {
                  onItemUpdate('brand', val);
                }}
                label={t('checkout_contact_information.parking.inputs.car_brand')}
                placeholder={t('checkout_contact_information.parking.inputs.car_brand_placeholder')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="model"
                onChange={(val) => {
                  onItemUpdate('model', val);
                }}
                label={t('checkout_contact_information.parking.inputs.car_model')}
                placeholder={t('checkout_contact_information.parking.inputs.car_model_placeholder')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack direction="row" gap={3}>
                <FormTextFieldController
                  name="year"
                  type="number"
                  onChange={(val) => {
                    onItemUpdate('year', val);
                  }}
                  label={t('checkout_contact_information.parking.inputs.car_year')}
                  placeholder={t('checkout_contact_information.parking.inputs.car_year_placeholder')}
                />
                <FormTextFieldController
                  name="color"
                  onChange={(val) => {
                    onItemUpdate('color', val);
                  }}
                  label={t('checkout_contact_information.parking.inputs.car_color')}
                  placeholder={t('checkout_contact_information.parking.inputs.car_color_placeholder')}
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2.5}>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="vehicleLicense"
                onChange={(val) => {
                  onItemUpdate('vehicleLicense', val);
                }}
                label={t('checkout_contact_information.parking.inputs.car_license_plate')}
                placeholder={t('checkout_contact_information.parking.inputs.car_license_placeholder')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormTextFieldController
                name="state"
                onChange={(val) => {
                  onItemUpdate('state', val);
                }}
                label={t('checkout_contact_information.parking.inputs.car_state')}
                placeholder={t('checkout_contact_information.parking.inputs.car_state_placeholder')}
              />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
