import {
  AssistedTicketFlight,
  AssistedTicketPassenger,
  DEFAULT_ASSISTED_TICKET_FLIGHT,
  DEFAULT_ASSISTED_TICKET_PASSENGER,
} from './assisted-ticket';
import { TicketAirline } from './ticket-airline';
import { TicketCompany } from './ticket-company';

export interface TicketUnaccompaniedMinorTutor {
  tutorEmail: string;
  tutorFirstName: string;
  tutorLastName: string;
  tutorTelephone: string;
}

export const DEFAULT_TICKET_UNACCOMPANIED_MINOR_TUTOR: TicketUnaccompaniedMinorTutor = {
  tutorEmail: '',
  tutorFirstName: '',
  tutorLastName: '',
  tutorTelephone: '',
};

export const TICKET_UNACCOMPANIED_MINOR_AGE = {
  MAX: 17,
  MIN: 13,
} as const;

export type TicketUnaccompaniedMinorTempFileId = string;

export interface TicketUnaccompaniedMinor {
  flightInformation: AssistedTicketFlight;
  passengerInformation: AssistedTicketPassenger;
  tempFileId: TicketUnaccompaniedMinorTempFileId;
  tutorInformation: TicketUnaccompaniedMinorTutor;
}

interface BuildDefaultTicketUnaccompaniedMinor {
  departureDate: string;
  /** It must be a uuid */
  tempFileId: TicketUnaccompaniedMinorTempFileId;
  ticketCompany: TicketCompany;
}

export const buildDefaultTicketUnaccompaniedMinor = (
  props: BuildDefaultTicketUnaccompaniedMinor,
): TicketUnaccompaniedMinor => {
  const points = props.ticketCompany === TicketCompany.SanDiego ? { origin: 'TIJ-TJX' } : { destination: 'TIJ-TJX' };

  return {
    flightInformation: { ...DEFAULT_ASSISTED_TICKET_FLIGHT, date: props.departureDate, ...points },
    passengerInformation: { ...DEFAULT_ASSISTED_TICKET_PASSENGER },
    tempFileId: props.tempFileId,
    tutorInformation: { ...DEFAULT_TICKET_UNACCOMPANIED_MINOR_TUTOR },
  };
};

const UNACCOMPANIED_MINOR_AIRLINES = [TicketAirline.Volaris, TicketAirline.Aeromexico] as const;

export const getUnaccomopaniedMinorAirlines = () => UNACCOMPANIED_MINOR_AIRLINES;

export enum TicketUnaccompaniedMinorDocumentation {
  UNMRForm = 'UNMR_Form',
  I94Permit = 'I94_Permit',
  SAMForm = 'SAM_Form',
  NotarizedLetter = 'Notarized_Letter',
  FMMForm = 'FMM_Form',
  Passport = 'Passport',
  Visa = 'Visa',
  TutorID = 'ID_Tutor',
}

export const canCrossToSouth = (destinationPlace: string, nationality: string) =>
  destinationPlace === 'Mexico' && nationality !== 'mex';
export const canOtherCrossToNorth = (destinationPlace: string, nationality: string) =>
  destinationPlace === 'USA' && nationality !== 'other';
export const canCrossToNorth = (destinationPlace: string, nationality: string) =>
  destinationPlace === 'USA' && nationality !== 'mex';
export const canNotAmericanCrossToNorth = (destinationPlace: string, nationality: string) =>
  destinationPlace === 'USA' && nationality !== 'usa';
