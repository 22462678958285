import { Yup } from "../../utils/yup-locale";

// ********************************************************************************
export type TravelClubMember = {
  firstName: string;
  lastName: string;
  email: string;
  acceptRegion: boolean;
  // dictates if the contact information is the same as the main traveler
  isSamePerson?: boolean;
}

export const DEFAULT_TRAVEL_CLUB_MEMBER: TravelClubMember = {
  firstName: '',
  lastName: '',
  email: '',
  acceptRegion: false,
  isSamePerson: false,
};

export const travelClubMemberSchema = Yup.object().shape({
  isSamePerson: Yup.boolean().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  acceptRegion: Yup.boolean().required()
});
