import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, styled } from '@mui/material';
import { useDropzone } from 'react-dropzone';

const StyledContainer = styled('div')(({ theme, isFocused, isDragAccept, isDragReject }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 8,
  borderStyle: 'dashed',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: 64,
  ...(isDragAccept && {
    borderColor: theme.palette.success.main,
  }),
  ...(isDragReject && {
    borderColor: theme.palette.error.main,
  }),
  ...(isFocused && {
    borderColor: theme.palette.primary.main,
  }),
}));

interface Props {
  onChange: (buffer: any, fileType: string) => void;
}

export const DropzoneUploader = ({ onChange }: Props) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<any[]>([]);

  const handleDrop = useCallback(async (acceptedFiles: any[]) => {
    const newFile = acceptedFiles[0];
    if (!newFile) return;

    setFiles([newFile]);

    const buffer = await generateFileBuffer(newFile);
    onChange(buffer, newFile.type);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } = useDropzone({
    accept: { 'image/*': ['.png', '.jpg', '.jpeg'], 'application/pdf': ['.pdf'] },
    onDrop: handleDrop,
    multiple: false,
    noClick: true, // Prevent opening file dialog on click
    noKeyboard: true, // Prevent opening file dialog on keyboard input
  });

  return (
    <section className="container" onClick={open}>
      <StyledContainer {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <Typography color="secondary.light" variant="body1">
          {files.length ? files[0].name : t('checkout_contact_information.documents.drag_n_drop_files_here')}
        </Typography>
      </StyledContainer>
    </section>
  );
};

function generateFileBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const buffer = event.target.result;
      resolve(buffer);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}
