import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TicketUnaccompaniedMinorTutor } from '../../../../domain/tickets';
import { Yup } from '../../../../utils/yup-locale';
import { Grid, Stack, Typography } from '@mui/material';
import { FormTextFieldController } from './components/text-field-controller';

const unaccompaniedMinorTutorSchema = Yup.object().shape({
  tutorEmail: Yup.string().email().required(),
  tutorFirstName: Yup.string().required(),
  tutorLastName: Yup.string().required(),
  tutorTelephone: Yup.string().required(),
});

interface Props {
  tutor: TicketUnaccompaniedMinorTutor;
  onChange: (key: keyof TicketUnaccompaniedMinorTutor, value: string) => void;
}

export const UnaccompaniedMinorTutorForm = ({ tutor, onChange }: Props) => {
  const { t } = useTranslation();

  const form = useForm<TicketUnaccompaniedMinorTutor>({
    mode: 'all',
    defaultValues: tutor,
    resolver: yupResolver(unaccompaniedMinorTutorSchema),
  });

  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={3}>
        <Typography variant="body1" color="secondary" fontWeight={700}>
          {t('checkout_contact_information.guardian.title')}
        </Typography>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="tutorFirstName"
              label={t('checkout_contact_information.guardian.inputs.name')}
              placeholder={t('checkout_contact_information.guardian.inputs.name_placeholder')}
              onChange={(value) => onChange('tutorFirstName', value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="tutorLastName"
              label={t('checkout_contact_information.guardian.inputs.last_name')}
              placeholder={t('checkout_contact_information.guardian.inputs.last_name_placeholder')}
              onChange={(value) => onChange('tutorLastName', value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2.5}>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="tutorEmail"
              type="email"
              label={t('checkout_contact_information.guardian.inputs.email')}
              placeholder={t('checkout_contact_information.guardian.inputs.email_placeholder')}
              onChange={(value) => onChange('tutorEmail', value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormTextFieldController
              name="tutorTelephone"
              label={t('checkout_contact_information.guardian.inputs.phone')}
              placeholder={t('checkout_contact_information.guardian.inputs.phone_placeholder')}
              onChange={(value) => onChange('tutorTelephone', value)}
            />
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  );
};
