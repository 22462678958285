import { Service, ServiceType } from '../common/service';
import { TravelClubMember, DEFAULT_TRAVEL_CLUB_MEMBER } from './travel-club-member';
import { TravelClubPlan } from './travel-club-plan';

// ********************************************************************************
export class TravelClub extends Service {
  // NOTE: This is a default plan for now, but it will be changed in the future
  //       to be dynamic based on the user's selection.
  public readonly plan: TravelClubPlan;
  public readonly member: TravelClubMember;

  // == Lifecycle =================================================================
  constructor(plan: TravelClubPlan) {
    super({
      type: ServiceType.TravelClub,
      quantity: 1 /*single*/,
      total: plan.hasDiscount ? plan.discountPrice : plan.price,
    });

    this.plan = plan;
    this.member = DEFAULT_TRAVEL_CLUB_MEMBER;
  }
}

// == Service Key ================================================================
export const TRAVEL_CLUB_SERVICE_KEY = 'travel_club_service';